import * as React from "react";
import Seo from "../components/seo";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import date from "../components/date";

import Layout from "../components/layout";

// markup
export default function Podcast({ data }) {
  const source = data.audio.edges;

  const [recorded, setRecorded] = React.useState(source);
  const [currentPhoto, setCurrentPhoto] = React.useState(0);
  const [currentPhotoId, setCurrentPhotoId] = React.useState(0);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isPhotoReady, setIsPhotoReady] = React.useState(false);
  const [sortMode, setSortMode] = React.useState("id-asc");

  const player = React.createRef(<audio></audio>);


  React.useEffect(() => {
    if (isPlaying && isPhotoReady) {
      player.current.play();
    }
  }, [currentPhoto, isPhotoReady]);


  React.useEffect(() => {

    player.current.pause();

  let newOrder = []

    switch(sortMode){
      case "date-desc": 
        newOrder = source.sort((a, b) => new Date(b.node.data.StoryDate) - new Date(a.node.data.StoryDate));
      break;
      case  "date-asc": 
      newOrder = source.sort((a, b) => new Date(a.node.data.StoryDate) - new Date(b.node.data.StoryDate));
      break;
      case  "id-asc": 
        newOrder = source.sort((a, b) => a.node.data.Id - b.node.data.Id);
      break;
      case  "id-desc": 
        newOrder = source.sort((a, b) => b.node.data.Id - a.node.data.Id);
      break;

    }


    setRecorded([...newOrder]);

    setCurrentPhoto(0);
    setCurrentPhotoId(newOrder[0].node.data.Id);
    setIsPlaying(false);


  }, [sortMode])

  const start = () => {
    if (player.current.paused) {
      
      setIsPlaying(true);
      player.current.play();
    } else {
      setIsPlaying(false);
      player.current.pause();
    }
  };

  const loadAudio = () => {
  };

  const loadPhoto = () => {
    setIsPhotoReady(true);
  };

  const playNext = async () => {
    setIsPhotoReady(false);
    if (currentPhoto < recorded.length - 1) {
      await setCurrentPhotoId(recorded[currentPhoto + 1].node.data.Id);
      await setCurrentPhoto((currentPhoto) => currentPhoto + 1);
    }
  };

  const playPrev = async () => {
    setIsPhotoReady(false);
    if (currentPhoto >= 1) {
      await setCurrentPhotoId(recorded[currentPhoto - 1].node.data.Id);
      await setCurrentPhoto((currentPhoto) => currentPhoto - 1);
    }
  };


  return (
    <Layout>
      <Seo title="Opowiedz mi Korsi" />
      <main>
        <div className="grid">
          <div className="photo">
            <div className="photo__background">
              <img
                src={recorded[currentPhoto].node.data.Photo}
                alt={`Zdjęcie wykonane `}
                className="photo__cover"
                onLoad={loadPhoto}
              />
            </div>
            <div className="photo__main">
              {" "}
              <img
                src={recorded[currentPhoto].node.data.Photo}
                alt={`Zdjęcie wykonane `}
              />
            </div>
            <h1 className="photo__title">
              #{recorded[currentPhoto].node.data.Id} (
              {date(recorded[currentPhoto].node.data.Id)})
            </h1>
          </div>

          <div className="podcast">
            <audio
              onEnded={playNext}
              onLoad={loadAudio}
              ref={player}
              src={recorded[currentPhoto].node.data.Audio}
            ></audio>
            <button
              className={`button button--control ${
                isPlaying ? "button--control-active" : ""
              }`}
              onClick={playPrev}
            >
            ←
            </button>
            <button className="button button--play" onClick={start}>
              {!isPlaying ? "Opowiadaj Korsi!" : "Ok, wystarczy."}
            </button>
            <button
              onClick={playNext}
              className={`button button--control ${
                isPlaying ? "button--control-active" : ""
              }`}
            >
             →
            </button>
          </div>
        </div>
        <div className="playlist">
          <div className="sorters">
          <button className={`sort ${sortMode == 'id-asc' ? 'sort--active' : ""}`} onClick={() => setSortMode("id-asc")}>Zdjęcia &uarr;</button>
          <button className={`sort ${sortMode == 'id-desc' ? 'sort--active' : ""}`}  onClick={() => setSortMode("id-desc")}>Zdjęcia &darr;</button>
          <button className={`sort ${sortMode == 'date-asc' ? 'sort--active' : ""}`}  onClick={() => setSortMode("date-asc")}>Historie &uarr;</button>
          <button className={`sort ${sortMode == 'date-desc' ? 'sort--active' : ""}`}  onClick={() => setSortMode("date-desc")}>Historie &darr;</button>
          </div>
          <ul className="playlist__list">
            {recorded.map((photo, i) => {
              return (
                <li key={i}>
                  <button
                    className={`playlist__item ${
                      photo.node.data.Id === currentPhotoId
                        ? "playlist__item--active"
                        : ""
                    }`}
                    onClick={async () => {
                      await setCurrentPhoto(i);
                      await setCurrentPhotoId(recorded[i].node.data.Id);
                    }}
                  >
                    <span>📷 #{photo.node.data.Id} </span>
                    <span className="small">🎙{new Date(photo.node.data.StoryDate).toLocaleString()}</span>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </main>
      <div className="home">
        <Link to="/">Wróć na stronę główną</Link>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    audio: allAirtable(
      sort: { fields: data___StoryDate, order: DESC }
      filter: { data: { Audio: { ne: null } } }
    ) {
      edges {
        node {
          data {
            Id
            Fav
            Photo
            Audio
            StoryDate
          }
        }
      }
    }
  }
`;
